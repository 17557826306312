<template>
    <div class="regulation__staff regulation__modal">
        <button type="button" class="btn-block-option close-modal" @click="$modal.hide('RegulationStaff')"> ×</button>
        <div class="modal__container">
            <div class="modal__header modal__header--desktop">
                <img :src="data.image || defaultAvatar" alt="" class="modal__avatar">
                <div class="modal__info">
                    <h3 class="modal__name">{{data.fio}}</h3>
                    <span class="modal__geo">{{data.city}}</span>
                    <span class="modal__descr" v-if="data.work_on_place">Возможна работа на территории заказчика</span>
                    <ul class="modal__contacts">
                        <li v-if="data.work_phone" class="modal__contact">
                            <span class="modal__contact-title">Телефон:</span>
                            <a :href="`tel:${data.work_phone}`" class="modal__contact-value">{{data.work_phone}}</a>
                        </li>
                        <li v-if="data.email" class="modal__contact">
                            <span class="modal__contact-title">E-mail:</span>
                            <a :href="`mailto:${data.email}`" class="modal__contact-value">{{data.email}}</a>
                        </li>
                        <li v-if="data.skype" class="modal__contact">
                            <span class="modal__contact-title">Skype:</span>
                            <a :href="`skype:${data.skype}?call`" class="modal__contact-value">{{data.skype}}</a>
                        </li>
                    </ul>
                </div>
                <h3 class="modal__price modal__price--desktop">{{data.price}} ₽/час</h3>
            </div>
            <div class="modal__header modal__header--mobile">
                <div class="modal__info-header">
                    <img :src="data.image || defaultAvatar" alt="" class="modal__avatar">
                    <div class="modal__info-wrap">
                        <h3 class="modal__name">{{data.fio}}</h3>
                        <span class="modal__geo">{{data.city}}</span>
                    </div>
                </div>
                <span class="modal__descr" v-if="data.work_on_place">Возможна работа на территории заказчика</span>
                <h3 class="modal__price">{{data.price}} ₽/час</h3>
            </div>
            <div class="modal__group">
                <ul class="modal__contacts modal__contacts--mobile">
                    <li v-if="data.work_phone" class="modal__contact">
                        <span class="modal__contact-title">Телефон:</span>
                        <a :href="`tel:${data.work_phone}`" class="modal__contact-value">{{data.work_phone}}</a>
                    </li>
                    <li v-if="data.email" class="modal__contact">
                        <span class="modal__contact-title">E-mail:</span>
                        <a :href="`mailto:${data.email}`" class="modal__contact-value">{{data.email}}</a>
                    </li>
                    <li v-if="data.skype" class="modal__contact">
                        <span class="modal__contact-title">Skype:</span>
                        <a :href="`skype:${data.skype}?call`" class="modal__contact-value">{{data.skype}}</a>
                    </li>
                </ul>
                <div class="modal__item modal__course">
                    <span class="modal__title">Образование</span>
                    <span class="modal__text" v-html="data.course"></span>
                </div>
                <div class="modal__item modal__exp">
                    <span class="modal__title">Опыт работы</span>
                    <span class="modal__text modal__exp-in-years v-bold">{{data.experience_in_years}}</span>
                    <span class="modal__text" v-html="data.experience"></span>
                </div>
                <div class="modal__wrap">
                    <div class="modal__item modal__languages">
                        <span class="modal__title">Язык составления документов</span>
                        <span class="modal__text">{{data.language}}</span>
                    </div>
                    <div class="modal__item modal__methods">
                        <div class="modal__title">Методологии</div>
                        <span class="modal__text v-bold">{{data.methodologies}}</span>
                    </div>
                </div>
                <div v-if="data.diplomas_files.length" class="modal__item modal__diplomas">
                    <span class="modal__title">Дипломы</span>
                    <ul class="diplomas-list">

                    </ul>
                </div>
                <div v-if="data.work_example_files.length" class="modal__item modal__examples">
                    <span class="modal__title">Примеры работ</span>
                </div>
                <div v-if="data.recommendation_files.length" class="modal__item modal__recommendations">
                    <span class="modal__title">Рекомендации</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';

    export default {
        name: "RegulationStaff",
        data() {
            return {
                defaultAvatar
            }
        },
        props: {
            data: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="sass">
    @import "#sass/v-style"

    .regulation__modal
        padding: 50px 75px
        display: flex
        flex-direction: column
    .modal
        &__header
            display: flex
            /*align-items: center*/
            &--mobile
                display: none
        &__avatar
            @include hard-size(200px,200px)
            border-radius: 50%
            padding: 8px
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07)
        &__info
            display: flex
            flex-direction: column
            margin-left: 20px
        &__geo
            font-size: 1rem
            font-weight: 600
            margin-top: 5px
        &__group
            max-width: 75%
        &__item
            display: flex
            flex-direction: column
            &:nth-child(n+2)
                margin-top: 30px
        &__course
            margin-top: 50px
        &__exp-in-years
            margin-bottom: 15px
        &__wrap
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: baseline
        &__title
            color: $text-gray
            margin-bottom: 10px
        &__text
            color: #000
        &__descr
            margin-top: auto
        &__contacts
            margin-top: auto
            &--mobile
                display: none
        &__contact
            display: flex
            &-title
                color: $text-gray
                flex-basis: 35%
            &-value
                color: $link-main
        &__price
            align-self: flex-start
            margin-left: auto

    @media (max-width: 600px)
        .regulation__modal
            padding: 20px
        .modal
            &__header
                &--desktop
                    display: none
                &--mobile
                    display: flex
                    flex-direction: column
            &__price
                &--desktop
                    display: none
            &__avatar
                @include hard-size(125px,125px)
            &__info
                &-header
                    display: flex
                    align-items: center
                &-wrap
                    display: flex
                    flex-direction: column
                    margin-left: 15px
            &__descr
                margin-top: 15px
            &__price
                margin-left: 0
                margin-top: 15px
            &__contacts
                margin-top: 30px
                &--mobile
                    display: block
            &__contact
                &-title
                    flex-basis: 45%
            &__wrap
                flex-direction: column
                margin-top: 30px


</style>
